import styled from 'styled-components';

const FullVisionTitleSC = styled.p`
  color: #001a72;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 1.14rem;
`;

const FullVisionDescriptionSC = styled.p`
  color: #646776;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const FullVisionDescriptionContainerSC = styled.div`
  /* height: calc(100% - 10px); */
  height: 77%;
  overflow-x: hidden;

  @media (max-width: 400px) {
    height: 73%;
  }
`;

const FullVisionNumberContainerSC = styled.div`
  display: flex;
  justify-content: space-between;

  position: absolute;
  bottom: 65px;
  right: 0.94rem;
  left: 0.94rem;

  bottom: 85px;
  @media (max-width: 768px) {
  }
`;

const FullVisionVendorCodeSC = styled.p`
  color: #646776;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const FullVisionPriceSC = styled.p`
  color: #001a72;
  text-align: right;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
`;

const FullVisionButtonSC = styled.button`
  position: absolute;
  bottom: 0.94rem;
  /* bottom: 0; */
  right: 0;
  left: 0;

  height: 63px;
  width: 100%;
  max-width: 15.5rem;
  cursor: pointer;

  margin: 0 auto;

  border: none;
  border-radius: 21px;
  background: linear-gradient(138deg, #2323b2 0%, #0a0d46 73.76%, #010620 100%);

  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const useFullVisionStyles = () => ({
  FullVisionDescriptionSC,
  FullVisionNumberContainerSC,
  FullVisionPriceSC,
  FullVisionTitleSC,
  FullVisionVendorCodeSC,
  FullVisionButtonSC,
  FullVisionDescriptionContainerSC,
});
