import React from 'react';
import 'normalize.css';
import GlobalStyle from './theme/GlobalStyles';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Advantages from './components/Advantages/Advantages';
import About from './components/About/About';
import Catalog from './components/Catalog/Catalog';
import Footer from './components/Footer/Footer';
import PopupState from './components/PopupState';

function App() {
  return (
    <>
      <Header />
      <main>
        <Main />
        <Advantages />
        <About />
        <Catalog />
        <Footer />
      </main>
      <PopupState />
      <GlobalStyle />
    </>
  );
}

export default App;
