import styled from 'styled-components';

const HeaderSC = styled('header')`
  position: sticky;
  top: 0;
  z-index: 1;
  background: linear-gradient(310.59deg, #f2f3f6 3.8%, #e5e6ec 119.99%);
  box-shadow: 3px 3px 20px rgba(36, 65, 93, 0.302);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 79px;
`;

const LogoContainerSC = styled('div')`
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  gap: 15px;
`;

const LogoTitleSC = styled.span`
  font-size: 42px;
  font-weight: 500;
  background-color: #010620;
  background-image: linear-gradient(132.34deg, #2323B3 16.21%, #0A0D46 87.11%, #010620 1.86%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 600px) {
    font-size: 32px;
  }
  @media (max-width: 375px) {
    font-size: 24px;
  }
  @media (max-width: 330px) {
    font-size: 20px;
  }
`

const SectionSC = styled('section')`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const NavSC = styled('nav')``;

const UlSC = styled('ul')`
  display: flex;
  gap: 70px;

  @media (max-width: 878px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const LiSC = styled('li')`
  position: relative;

  color: #646776;
  @media (max-width: 400px) {
    font-size: 14px;
  }
  //list-style-type: none;
`;

const ButtontSC = styled('button')`
  background: none;

  cursor: pointer;

  padding: 0;
  color: inherit;
  border: 0;
`;

const BasketNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  /* width: 20px; */
  min-width: 20px;
  height: 20px;

  right: -15px;
  bottom: -8px;

  border-radius: 20px;
  background: #001a72;
  border: 3px solid #fff;

  color: #fff;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  padding: 1px;

  @media (max-width: 878px) {
    right: 41px;
    bottom: -8px;
  }

  @media (max-width: 400px) {
    right: 19px;
    bottom: -5px;
  }
`;

export const useHeaderStyles = () => ({
  BasketNumber,
  ButtontSC,
  HeaderSC,
  LiSC,
  LogoContainerSC,
  NavSC,
  SectionSC,
  UlSC,
  LogoTitleSC,
});
