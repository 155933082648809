import React from "react";

const Logo = () => {
  return (
      <svg width="49" height="49" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="clip0_33_362">
            <rect fill="white" height="49" id="svg_1" width="48"/>
          </clipPath>
          <linearGradient id="paint1_linear_33_362" x1="-0.11" x2="1.07" y1="-0.21" y2="1.43">
            <stop stopColor="#2323B3"/>
            <stop offset="0.74" stopColor="#0A0D46"/>
            <stop offset="1" stopColor="#010620"/>
          </linearGradient>
          <linearGradient id="paint2_linear_33_362" x1="-0.11" x2="1.07" y1="-0.21" y2="1.42">
            <stop stopColor="#2323B3"/>
            <stop offset="0.74" stopColor="#0A0D46"/>
            <stop offset="1" stopColor="#010620"/>
          </linearGradient>
          <linearGradient id="paint3_linear_33_362" x1="-0.11" x2="1.09" y1="-0.21" y2="1.4">
            <stop stopColor="#2323B3"/>
            <stop offset="0.74" stopColor="#0A0D46"/>
            <stop offset="1" stopColor="#010620"/>
          </linearGradient>
          <linearGradient id="paint4_linear_33_362" x1="-0.11" x2="0.99" y1="-0.21" y2="1.51">
            <stop stopColor="#2323B3"/>
            <stop offset="0.74" stopColor="#0A0D46"/>
            <stop offset="1" stopColor="#010620"/>
          </linearGradient>
        </defs>
        <g className="layer">
          <title>Layer 1</title>
          <g clip-path="url(#clip0_33_362)" id="svg_3">
            <path
                d="m48,16.69l-2.58,-4.43c-2.84,0.75 -5.63,1.63 -8.39,2.65c2.01,2.67 3.2,5.97 3.2,9.56c0,8.85 -7.27,16.03 -16.25,16.03c-6.62,0 -12.33,-3.92 -14.85,-9.54c-2.25,1.87 -4.41,3.84 -6.47,5.91l2.53,4.34c1.33,-0.76 2.87,-1.17 4.53,-1.17c5.01,0 9.08,4 9.08,8.98l10.39,0c0,-4.98 4.07,-8.98 9.08,-8.98c1.65,0 3.18,0.41 4.51,1.17l5.21,-8.94c-2.72,-1.55 -4.57,-4.45 -4.57,-7.79c0.01,-3.34 1.85,-6.24 4.58,-7.79z"
                fill="url(#paint1_linear_33_362)" id="svg_4"/>
            <path
                d="m9.14,30.95c-0.89,-1.98 -1.38,-4.17 -1.38,-6.48c0,-8.85 7.27,-16.03 16.24,-16.03c5.34,0 10.09,2.55 13.04,6.47c2.76,-1.02 5.55,-1.9 8.39,-2.65l-2.63,-4.51c-1.33,0.75 -2.86,1.19 -4.51,1.19c-5.01,0 -9.08,-4.06 -9.08,-8.94l-10.39,0c0,4.88 -4.07,8.94 -9.08,8.94c-1.65,0 -3.19,-0.43 -4.53,-1.19l-5.21,8.93c2.73,1.55 4.58,4.45 4.58,7.79c0,3.34 -1.85,6.24 -4.58,7.79l2.67,4.6c2.06,-2.07 4.22,-4.04 6.47,-5.91z"
                fill="url(#paint2_linear_33_362)" id="svg_5"/>
            <path
                d="m23.99,39.54c8.44,0 15.27,-6.75 15.27,-15.07c0,-3.47 -1.18,-6.65 -3.18,-9.2c-0.82,0.31 -1.64,0.63 -2.46,0.97c1.94,2.2 3.11,5.08 3.11,8.23c0,6.94 -5.7,12.57 -12.74,12.57c-5.53,0 -10.25,-3.49 -12,-8.36c-0.7,0.54 -1.4,1.09 -2.08,1.64c2.32,5.41 7.76,9.22 14.08,9.22z"
                fill="url(#paint3_linear_33_362)" id="svg_6"/>
            <path
                d="m11.26,24.47c0,-6.94 5.7,-12.57 12.74,-12.57c3.84,0 7.29,1.68 9.62,4.34c0.82,-0.33 1.64,-0.65 2.46,-0.97c-2.79,-3.57 -7.17,-5.87 -12.08,-5.87c-8.44,0 -15.27,6.75 -15.27,15.07c0,2.07 0.42,4.04 1.19,5.85c0.69,-0.56 1.37,-1.1 2.08,-1.64c-0.48,-1.32 -0.74,-2.74 -0.74,-4.21z"
                fill="url(#paint4_linear_33_362)" id="svg_7"/>
          </g>
        </g>
      </svg>
  );
};

export default React.memo(Logo);
