import styled from 'styled-components';

const ExtandSC = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.4);
`;

const ReplyIconSC = styled.img`
  cursor: pointer;

  position: absolute;
  right: 0.94rem;
  top: 0.94rem;
`;

const ContainerSC = styled.div`
  position: relative;

  border-radius: 1.625rem;
  background: #fff;

  width: 100%;

  max-width: 50rem;

  @media (max-width: 878px) {
    width: 90%;
  }
`;

const TextContainerSC = styled.div`
  padding: 1.16rem;
`;

const TitleSC = styled.p`
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 488px) {
    font-size: 0.9rem;
  }
`;

const DescriptionSC = styled.p`
  color: #646776;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 0.63rem;

  @media (max-width: 488px) {
    font-size: 0.9rem;
  }
`;

export const usePopupStateStyles = () => ({
  ContainerSC,
  DescriptionSC,
  ExtandSC,
  ReplyIconSC,
  TextContainerSC,
  TitleSC,
});
