import styled from 'styled-components';

const ArticleSC = styled.article`
  /* max-width: 248px; */
  width: 248px;
  height: 553px;

  position: relative;
`;

const ImgSC = styled.img`
  width: 100%;
  height: 248px;

  object-fit: cover;

  margin-bottom: 12px;

  display: block;
`;

const ContainerSC = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleSC = styled.h5`
  overflow: hidden;
  color: #001a72;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: normal;
`;

const DescriptionSC = styled.p`
  overflow: hidden;
  color: #646776;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const NumberContainerSC = styled.div`
  position: absolute;
  bottom: 80px;
  right: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VendorSC = styled.p`
  color: #646776;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const PriceSC = styled.p`
  color: #001a72;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const ButtonSC = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  height: 63px;
  width: 100%;
  max-width: 15.5rem;
  cursor: pointer;

  margin: 0 auto;
  border: none;

  border-radius: 21px;
  background: linear-gradient(138deg, #2323b2 0%, #0a0d46 73.76%, #010620 100%);

  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const useCatalogCardStyles = () => ({
  ArticleSC,
  ButtonSC,
  DescriptionSC,
  ImgSC,
  NumberContainerSC,
  PriceSC,
  TitleSC,
  VendorSC,
  ContainerSC,
});
