import { usePopupState } from './usePopupState';

import { usePopupStateStyles } from './usePopupStateStyles';

const PopupState = () => {
  const { isVisible, state, handleClose } = usePopupState();

  return (
    (isVisible && (
      <ExtandSC onClick={handleClose}>
        <ContainerSC>
          <ReplyIconSC src="ReplyIcon.svg" />
          {state === 'success' && (
            <TextContainerSC>
              <TitleSC style={{ color: '#001A72' }}>
                Оплата успешно произведена
              </TitleSC>
            </TextContainerSC>
          )}
          {state === 'fail' && (
            <TextContainerSC>
              <TitleSC style={{ color: '#EA3E3E' }}>
                Произошла ошибка при оплате
              </TitleSC>
              <DescriptionSC>Пожалуйста, попробуйте еще раз.</DescriptionSC>
            </TextContainerSC>
          )}
        </ContainerSC>
      </ExtandSC>
    )) ||
    null
  );
};

const {
  ContainerSC,
  DescriptionSC,
  ExtandSC,
  ReplyIconSC,
  TextContainerSC,
  TitleSC,
  // eslint-disable-next-line react-hooks/rules-of-hooks
} = usePopupStateStyles();

export default PopupState;
