import { v4 as uuidv4 } from 'uuid';
import React, {useState} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import BasketItem from './BasketItem/BasketItem';

import { useBasket } from './useBasket';
import {DISABLE_PAYMENT, EMAIL_JS} from "../../../lib/constants/constants";
import ButtonUI from "../../../UI/ButtonUI/ButtonUI";
import {useController, useForm} from "react-hook-form";
import emailJs from "@emailjs/browser";

type ModalState = 'contacts' | 'requisites' | 'About' | 'basket' | '';

interface IModalUIProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const Basket = ({ isOpen, className = '', handleClose }: IModalUIProps) => {
  const {
    data,
    totalPrice,
    state,
    setState,
    removeAll,
    removeItem,
    decreaseQuantity,
    increaseQuantity,
    onClickOverlay,
  } = useBasket({ handleClose, isOpen });

  const [buttonLoading, setButtonLoading] = useState("Отправить")

  const handleNextStep = () => {
    setState(!state);
  };

  const {control, handleSubmit, reset} = useForm<{FIO: string, email: string}>({defaultValues: {
      FIO: "", email: "",
    }});

  const fioController = useController({control, name: "FIO", rules: {required: true}})
  const emailController = useController({control, name: "email", rules: {required: true}})


  const onSubmit = handleSubmit((data) => {
    setButtonLoading("Отправка данных...");
    emailJs
        .send(
            EMAIL_JS.serviceID,
            EMAIL_JS.templateID,
            {...data, agree: "Да"},
            EMAIL_JS.publicKey,
        )
        .then(() => {
          setButtonLoading("Отправить")
          reset({FIO: "", email: ""});
          setState(false)
          handleClose('')();
          removeAll()
        })
        .catch(() => {
          reset({FIO: "", email: ""});
          alert("Ошибка отправки данных!")
        });
  })

  return isOpen ? (
    <>
      {!state && (
          <ModalContainerSC onClick={onClickOverlay} className={className}>
            <ContainerSC>
              <TitleSC>Корзина</TitleSC>
              <ReplyIconSC onClick={handleClose('')} src="ReplyIcon.svg" />
              {data.length >= 1 && (
                  <RemoveAllButtonSC onClick={removeAll}>
                    Очистить корзину
                  </RemoveAllButtonSC>
              )}
              {data.length < 1 && (
                  <>
                    <TitleSC style={{ marginTop: '80px' }}>
                      Вы ничего не добавили!
                    </TitleSC>
                  </>
              )}
              <div style={{ flex: '8', maxHeight: '450px' }}>
                <ItemsContainerSC>
                  {data.map((item, index) => (
                      <BasketItem key={index} {...item} />
                  ))}
                </ItemsContainerSC>
              </div>
              {data.length > 0 && (
                  <NumberContainerSC>
                    <SumSC>Итого:</SumSC>
                    <SumPriceSC>{totalPrice} ₽</SumPriceSC>
                  </NumberContainerSC>
              )}
              {
                DISABLE_PAYMENT === "false" ?
                    <form
                        method="POST"
                        // action="https://demo.paykeeper.ru/create/"
                        action="https://metall-tech74.server.paykeeper.ru/create/"
                        acceptCharset="utf-8"
                        style={{margin: '0 auto', width: '100%', textAlign: 'center'}}
                    >
                      <input
                          style={{display: 'none'}}
                          type="text"
                          name="sum"
                          value={totalPrice}
                      />
                      <input
                          style={{display: 'none'}}
                          type="text"
                          name="orderid"
                          accept-charset="utf-8"
                          value={uuidv4()}
                      />
                      <input
                          style={{display: 'none'}}
                          type="text"
                          name="user_result_callback"
                          value={'https://metall-tech74.ru/'}
                          // value={'http://localhost:3000/'}
                      />
                      <ButtonSC type="submit" disabled={data.length < 1}>
                        Оплатить
                      </ButtonSC>
                    </form>
                    :
                    <ButtonSC disabled={data.length < 1} onClick={handleNextStep}>
                      Заказать
                    </ButtonSC>
              }
            </ContainerSC>
          </ModalContainerSC>
      )}
      {state && (
          <ModalContainerSC onClick={onClickOverlay} className={className}>
            <ContainerSC>

              <TitleSC>
                Оставьте заявку и наш менеджер свяжется с вами
              </TitleSC>
              <ReplyIconSC onClick={() => setState(false)} src="ExitButton.svg"/>
              <InnerContainerSC className="container">
                <FormSC onSubmit={onSubmit}>
                  <FormContainerSC>
                    <InputBasketFormUI
                        placeholder="ФИО"
                        name="name"
                        value={fioController.field.value}
                        onChange={(e) => fioController.field.onChange(e.target.value)}
                        required={true}
                    />
                    <InputBasketFormUI
                        placeholder="E-mail"
                        name="email"
                        value={emailController.field.value}
                        onChange={(e) => emailController.field.onChange(e.target.value)}
                        type="email"
                        required={true}
                    />
                  </FormContainerSC>
                  <ButtonUI style={{width: "auto"}}>{buttonLoading}</ButtonUI>
                </FormSC>
              </InnerContainerSC>
            </ContainerSC>
          </ModalContainerSC>
      )}
      {createPortal(
          <ModalBackdoorSC onClick={handleClose('')}/>,
          document.querySelector('#root') as HTMLElement
      )}
    </>
  ) : null;
};

const ModalContainerSC = styled('div')`
  width: 100%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
`;

const TitleSC = styled.p`
  color: #001a72;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding-right: 20px;
  text-align: center;
`;

const ModalBackdoorSC = styled('div')`
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ContainerSC = styled.div`
  z-index: 5;
  position: relative;
  width: 50%;
  max-width: 48rem;
  height: 41rem;
  background: #fff;
  border-radius: 1.625rem;
  padding: 1.16rem;

  display: flex;
  flex-direction: column;

  @media (max-width: 1174px) {
    width: 90%;
    max-width: none;
  }
`;

const NumberContainerSC = styled.div`
  /* position: absolute; */
  left: 0.94rem;
  right: 0.94rem;
  bottom: 80px;

  flex: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SumSC = styled.p`
  color: #646776;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const SumPriceSC = styled.p`
  color: #001a72;
  text-align: right;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
`;

const ItemsContainerSC = styled.div`
  flex: 8;

  display: grid;
  flex-direction: column;
  grid-gap: 0.62rem;
  grid-template-rows: 1fr;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Цвет скролла */
  &::-webkit-scrollbar-thumb {
    background-color: #d6d9e3;
  }

  /* Скрытие канавки скролла */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* height: calc(100% - 95px * 2); */
  /* max-height: calc(100% - 75px * 2); */
  max-height: calc(100% - 7px * 2);
`;

const ButtonSC = styled.button`
  flex: 1;
  /* position: absolute; */
  bottom: 0.94rem;
  right: 0.94rem;
  left: 0.94rem;

  border: none;

  height: 63px;
  width: 100%;
  max-width: 15.5rem;
  margin: 0 auto;
  text-align: center;

  cursor: pointer;

  border-radius: 21px;
  border-radius: 1.3125rem;
  background: #001a72;

  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:disabled {
    cursor: default;
    background: gray;
  }
`;

const ReplyIconSC = styled.img`
  cursor: pointer;

  position: absolute;
  right: 0.94rem;
  top: 0.94rem;
`;

const RemoveAllButtonSC = styled.button`
  cursor: pointer;

  margin-top: 10px;
  margin-bottom: 0.65rem;

  text-align: right;

  align-self: flex-end;

  background: none;
  border: none;

  color: #646776;
  text-align: right;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

///////////////////////////////////////////

const InputBasketFormUI = styled("input")`
  width: 100%;
  padding: 21px 33px;
  border: 0;
  height: 63px;
  background: lightgray;
  border-radius: 20px;
  font-family: inherit;
  &::placeholder {
    font-family: inherit;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 400;
  }
`;

const InnerContainerSC = styled("div")`
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 18.56px;
`;

const FormSC = styled("form")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
`;

const FormContainerSC = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
`;

const AgreeContainerSC = styled("div")`
  display: flex;
  align-items: center;
`;

const ParagraphSC = styled("p")`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  padding-left: 12px;
`;

const Checkbox = styled("button")`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 2px solid white;
  border-radius: 5px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`

///////////////////////////////////////////////////////////////////////////


export default React.memo(Basket);
