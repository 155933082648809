import React from 'react';
import { WrapperSC } from '../../UI/WrapperSC';
import { TitleSC } from '../../UI/TitleSC';
import styled from 'styled-components';

const About = () => {
  return (
    <WrapperSC style={{ paddingBottom: '113px' }} className="container-two">
      <TitleSC>Наша деятельность</TitleSC>
      <ParagraphSC>
        Компания МеталлТех зарекомендовала себя как солидный и надежный партнер
        для многих компаний работающих в области закупок, проектирования и
        производства деталей. У нас вы можете найти запчасти для любой
        спецтехники. Широкий ассортимент и приятные цены ждут вас! Мы
        гарантируем, что представленные товары прослужат долго. Покупайте у нас,
        ведь все наши запчасти оригинальные.
      </ParagraphSC>
    </WrapperSC>
  );
};

const ParagraphSC = styled('p')`
  font-size: 20px;
  text-align: center;
  color: #646776;
  margin-bottom: 39px;
`;

export default React.memo(About);
