import { useState } from 'react';

import useStore from '../storeZustand';

type ModalState = 'contacts' | 'requisites' | 'About' | 'basket' | '';

export const useHeader = () => {
  const [modal, setModal] = useState<ModalState>('');
  const [basket, setBasket] = useState<ModalState>('');
  const [requisites, setRequisites] = useState<ModalState>('');

  const { totalCount } = useStore((state) => {
    const dataLength = state.data.length; // Длина массива data

    // Подсчет общего количества элементов (сумма quantity всех элементов)
    const totalCount = state.data.reduce(
      (total, item) => total + item.quantity,
      0
    );

    return {
      dataLength,
      totalCount,
    };
  });

  const handleModalClose = (data: ModalState) => {
    return () => {
      setModal(data);
      setBasket('');
      setRequisites('');
    };
  };

  const handleBasketClose = (data: ModalState) => {
    return () => {
      setBasket(data);
      setRequisites('');
      setModal('');
    };
  };

  const handleRequisites = (data: ModalState) => {
    return () => {
      setRequisites(data);
      setModal('');
      setBasket('');
    };
  };

  return {
    basket,
    modal,
    requisites,
    totalCount,

    handleBasketClose,
    handleModalClose,
    handleRequisites,
  };
};
