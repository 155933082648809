import { useState } from 'react';

import useStore from '../../storeZustand';

type ModalState = 'card' | 'requisites' | 'About' | '';

type CatalogCardProps = {
  name: string;
  price: string;
  description?: string;
  vendor_code: string;
  image: string;
};

export const useCatalogCard = ({
  name,
  price,
  vendor_code,
  image,
}: CatalogCardProps) => {
  const { increase, addCard } = useStore();
  const [modal, setModal] = useState<ModalState>('');

  const handleModalClose = (data: ModalState) => {
    return () => {
      setModal(data);
    };
  };

  const handleAddToCart = () => {
    addCard({
      name,
      price,
      vendor_code,
      image,
      description: '',
      quantity: 0,
    });
    increase();
  };

  return { modal, handleModalClose, handleAddToCart };
};
