import {useEffect, useState} from 'react';

import useStore from '../../storeZustand';

type ModalState = 'contacts' | 'requisites' | 'About' | 'basket' | '';

interface useBasketProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
}

export const useBasket = ({ handleClose, isOpen }: useBasketProps) => {
  const { data, removeAll, removeItem, decreaseQuantity, increaseQuantity } =
    useStore();

  const [state, setState] = useState(false)

  const totalPrice = data.reduce(
    (accumulator, item) => accumulator + Number(item.price) * item.quantity,
    0
  );

  const onKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleClose('')();
    }
  };

  const onClickOverlay = (e: React.MouseEvent<HTMLElement>) => {
    handleClose('');

    if ((e.target as HTMLElement).classList.contains('basketModal')) {
      handleClose('')();
      setState(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      window.addEventListener('keydown', onKey);
    } else {
      document.body.style.overflowY = 'scroll';
      window.removeEventListener('keydown', onKey);
    }
    return () => {
      window.removeEventListener('keydown', onKey);
    };
  }, [isOpen]);

  return {
    data,
    totalPrice,
    state,
    setState,
    removeAll,
    removeItem,
    decreaseQuantity,
    increaseQuantity,

    onClickOverlay,
  };
};
