import React from 'react';
import FullVision from './FullVision/FullVision';

import { useCatalogCard } from './useCatalogCard';

import { useCatalogCardStyles } from './useCatalogCardStyles';
import { useFullVisionStyles } from './FullVision/FullVisionStyles';

import {BASE_URL} from '../../../lib/constants/constants';

type CatalogCardProps = {
  name: string;
  price: string;
  description: string;
  vendor_code: string;
  image: string;
};

const CatalogCard = ({
                       name,
                       price,
                       description,
                       vendor_code,
                       image,
                     }: CatalogCardProps) => {
  const { modal, handleModalClose, handleAddToCart } = useCatalogCard({
    name,
    price,
    vendor_code,
    image,
  });
  return (
      <>
        <ArticleSC>
          <div onClick={handleModalClose('card')}>
            <ImgSC src={`${BASE_URL}${image}`} />
            <ContainerSC>
              <TitleSC>{name}</TitleSC>
              <DescriptionSC>{description}</DescriptionSC>
              <NumberContainerSC>
                <VendorSC>{vendor_code}</VendorSC>
                <PriceSC>{price} ₽</PriceSC>
              </NumberContainerSC>
            </ContainerSC>
          </div>
          <ButtonSC onClick={handleAddToCart} type="button">
            В корзину
          </ButtonSC>
        </ArticleSC>
        <FullVision isOpen={modal !== ''} handleClose={handleModalClose}>
          {modal === 'card' && (
              <>
                <FullVisionTitleSC>{name}</FullVisionTitleSC>
                <FullVisionDescriptionContainerSC>
                  <FullVisionDescriptionSC>{description}</FullVisionDescriptionSC>
                </FullVisionDescriptionContainerSC>
                <FullVisionNumberContainerSC>
                  <FullVisionVendorCodeSC>{vendor_code}</FullVisionVendorCodeSC>
                  <FullVisionPriceSC>{price} ₽</FullVisionPriceSC>
                </FullVisionNumberContainerSC>
                <FullVisionButtonSC onClick={handleAddToCart} type="button">
                  В корзину
                </FullVisionButtonSC>
              </>
          )}
        </FullVision>
      </>
  );
};

const {
  FullVisionDescriptionSC,
  FullVisionNumberContainerSC,
  FullVisionPriceSC,
  FullVisionTitleSC,
  FullVisionVendorCodeSC,
  FullVisionButtonSC,
  FullVisionDescriptionContainerSC,
  // eslint-disable-next-line react-hooks/rules-of-hooks
} = useFullVisionStyles();

const {
  ArticleSC,
  ButtonSC,
  DescriptionSC,
  ImgSC,
  PriceSC,
  TitleSC,
  VendorSC,
  ContainerSC,
  NumberContainerSC,
  // eslint-disable-next-line react-hooks/rules-of-hooks
} = useCatalogCardStyles();

export default CatalogCard;
