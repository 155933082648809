import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

type ModalState = 'card' | 'requisites' | 'About' | '';

interface IModalUIProps {
  isOpen: boolean;
  children: ReactNode;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const FullVision = ({
  isOpen,
  children,
  className = '',
  handleClose,
}: IModalUIProps) => {
  const onKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleClose('');
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      window.addEventListener('keydown', onKey);
    } else {
      document.body.style.overflowY = 'scroll';
      window.removeEventListener('keydown', onKey);
    }
    return () => {
      window.removeEventListener('keydown', onKey);
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <ModalContainerSC onClick={handleClose('')} className={className}>
        <ContainerSC>
          {children}
          <ReplyIconSC onClick={handleClose('')} src="ReplyIcon.svg" />
        </ContainerSC>
      </ModalContainerSC>
      {createPortal(
        <ModalBackdoorSC onClick={handleClose('')} />,
        document.querySelector('#root') as HTMLElement
      )}
    </>
  ) : null;
};

const ReplyIconSC = styled.img`
  cursor: pointer;

  position: absolute;
  right: 0.94rem;
  top: 0.94rem;

  z-index: 6;
`;

const ModalContainerSC = styled('div')`
  width: 100%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBackdoorSC = styled('div')`
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ContainerSC = styled.div`
  z-index: 5;
  position: relative;
  width: 50%;
  max-width: 48rem;
  height: 41rem;
  background: #fff;
  border-radius: 1.625rem;
  padding: 1.16rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export default React.memo(FullVision);
