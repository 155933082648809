import styled from 'styled-components';

import { TitleSC } from '../../UI/TitleSC';

import { DataPost } from '../../lib/mock/posts';

import CatalogCard from './CatalogCard/CatalogCard';

const Catalog = () => {
  const CardsElements = DataPost.map((item, index) => (
    <CatalogCard {...item} key={index} />
  ));

  return (
    <>
      <WrapperSC className="container-two">
        <TitleSC>Каталог</TitleSC>
        <CatalogSC>{CardsElements}</CatalogSC>
      </WrapperSC>
    </>
  );
};

const CatalogSC = styled.div`
  position: relative;

  display: grid;
  justify-items: center;
  align-content: center;
  grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));

  grid-gap: 60px 10px;
`;

const WrapperSC = styled.section`
  padding-bottom: 60px;
`;

export default Catalog;
