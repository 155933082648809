import styled from 'styled-components';
import {DISABLE_PAYMENT} from "../../lib/constants/constants";

const Footer = () => {
  return (
    <FooterSC>
        { DISABLE_PAYMENT === "false" &&
            <>
                <LogoSC src="paykeeper.svg"/>
                <LogoSC src="visa.svg" />
                <LogoSC src="mastercard.svg" />
                <LogoSC src="mir.svg" />
            </>
        }

    </FooterSC>
  );
};

const FooterSC = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 13px;
`;

const LogoSC = styled.img`
  padding: 53px 0;

  width: max-content;

  object-fit: cover;
`;

export default Footer;
