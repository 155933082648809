import { useState, useEffect } from 'react';
import useStore from '../storeZustand';

export const usePopupState = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [state, setState] = useState('fail');

  const removeAll = useStore((state) => state.removeAll);

  const handleClose = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('payment_id');
    url.searchParams.delete('result');
    window.history.replaceState({}, document.title, url.toString());
    setIsVisible(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const result = searchParams.get('result');

    if (result === 'success') {
      setIsVisible(true);
      setState('success');
      removeAll();
    } else if (result === 'fail') {
      setIsVisible(true);
      setState('fail');
    }

    setIsVisible(Boolean(result));
  }, []);

  return { isVisible, state, handleClose };
};
