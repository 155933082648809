import React from 'react';
import Logo from '../Logo/Logo';
import ModalUI from '../../UI/ModalUI/ModalUI';
import Basket from './Basket';
import Requsites from './Requisites/Requisites';
import {useHeader} from './useHeader';

import {useHeaderStyles} from './useHeaderStyles';

const Header = () => {
    const {
        basket,
        modal,
        requisites,
        totalCount,

        handleRequisites,
        handleBasketClose,
        handleModalClose,
    } = useHeader();

    return (
        <HeaderSC>
            <SectionSC className="container">
                <LogoContainerSC>
                    <Logo/>
                    <LogoTitleSC>
                        {process.env.REACT_APP_TITLE}
                    </LogoTitleSC>
                </LogoContainerSC>
                <NavSC>
                    <UlSC>
                        <LiSC>
                            <ButtontSC onClick={handleBasketClose('basket')} type="button">
                                Корзина
                            </ButtontSC>
                            <BasketNumber>{totalCount}</BasketNumber>
                        </LiSC>
                        <LiSC>
                            <ButtontSC onClick={handleRequisites('requisites')} type="button">
                                Для партнеров
                            </ButtontSC>
                        </LiSC>
                        <LiSC>
                            <ButtontSC onClick={handleModalClose('contacts')} type="button">
                                Контакты
                            </ButtontSC>
                        </LiSC>
                    </UlSC>
                </NavSC>
                <ModalUI isOpen={modal !== ''} handleClose={handleModalClose('')}>
                    {modal === 'contacts' && (
                        <>
                            ИП Дерюгин Дмитрий Сергеевич <br/> Адрес: г.Челябинск, ул. Производственная, д. 8а, ст.
                            1 <br/>{' '}
                            Телефон: +7(919)117-30-24 <br/> Email: ipderyugin@bk.ru <br/>
                            Офис: Бизнес центр Т-11 Троицкий тракт 11л, 210
                        </>
                    )}
                    {modal === 'About' && (
                        <>
                            <a href="mailto:ipderyugin@bk.ru">Email: ipderyugin@bk.ru</a>
                            <br/>
                            <br/>
                            <a href="tel:+79191173024">Телефон: +7(919)117-30-24</a>
                        </>
                    )}
                </ModalUI>
                <Basket
                    isOpen={basket === 'basket'}
                    handleClose={handleBasketClose}
                    className="basketModal"
                />
                <Requsites
                    isOpen={requisites === 'requisites'}
                    handleClose={handleRequisites}
                    className="req"
                />
            </SectionSC>
        </HeaderSC>
    );
};

const {
    BasketNumber,
    ButtontSC,
    HeaderSC,
    LiSC,
    LogoContainerSC,
    NavSC,
    SectionSC,
    UlSC,
    LogoTitleSC
    // eslint-disable-next-line react-hooks/rules-of-hooks
} = useHeaderStyles();

export default React.memo(Header);
