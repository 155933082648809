import { useBasketItem } from './useBasketItem';

import { useBasketItemStyles } from './BasketItemStyles';

import { BASE_URL } from '../../../../lib/constants/constants';
import { CardExcludeDescription } from '../../../storeZustand/type';

const BasketItem = (props: CardExcludeDescription) => {
  const { decreaseQuantity, increaseQuantity, removeItem, setQuantity } = useBasketItem();
  return (
    <BasketItemWrapper>
      <BasketItemImageSC src={`${BASE_URL}${props.image}`} />
      <BasketItemContainerSC>
        <BasketItemTitleSC>{props.name}</BasketItemTitleSC>
        <BasketItemPriceSC>{props.price} ₽</BasketItemPriceSC>
        <BasketItemVendorSC>{props.vendor_code}</BasketItemVendorSC>
      </BasketItemContainerSC>
      <BasketItemControllSC>
        <BasketItemControllContainerSC>
          <BasketItemDeacreaseSC onClick={() => decreaseQuantity(props)}>
            -
          </BasketItemDeacreaseSC>
          <BasketItemQuantity
              type={"number"}
              value={props.quantity}
              onChange={(e) => setQuantity(Number(e.target.value), props)}
              onBlur={(e) => {
                e.target.value === "0" && removeItem(props);
              }}
          />
          <BasketItemIncreaseSC onClick={() => increaseQuantity(props)}>
            +
          </BasketItemIncreaseSC>
        </BasketItemControllContainerSC>
        <BasketItemDeleteItemSC
          onClick={() => removeItem(props)}
          src="deleteIcon.svg"
        />
      </BasketItemControllSC>
    </BasketItemWrapper>
  );
};

const {
  BasketItemWrapper,
  BasketItemImageSC,
  BasketItemPriceSC,
  BasketItemTitleSC,
  BasketItemVendorSC,
  BasketItemContainerSC,
  BasketItemControllSC,
  BasketItemDeacreaseSC,
  BasketItemIncreaseSC,
  BasketItemQuantity,
  BasketItemDeleteItemSC,
  BasketItemControllContainerSC,
  // eslint-disable-next-line react-hooks/rules-of-hooks
} = useBasketItemStyles();

export default BasketItem;
