import styled from 'styled-components';

const BasketItemWrapper = styled.article`
    height: 8.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 0.62rem;
`;

const BasketItemImageSC = styled.img`
    object-fit: cover;

    width: 8.5rem;
    height: 8.5rem;

    @media (max-width: 500px) {
        width: 6.5rem;
        height: 6.5rem;
    }
`;

const BasketItemContainerSC = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* justify-content: space-between; */

    flex-grow: 2;

    height: 100%;
    justify-content: space-between;

    @media (max-width: 500px) {
        height: 79%;
    }
`;

const BasketItemTitleSC = styled.p`
    color: #001a72;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 768px) {
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const BasketItemPriceSC = styled.p`
    color: #001a72;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

const BasketItemVendorSC = styled.p`
    color: #646776;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

const BasketItemControllSC = styled.div`
    display: flex;
    justify-content: center;
    justify-content: space-around;
    align-items: center;

    height: 2.5rem;
    /* flex-basis: 190px; */
`;

const BasketItemControllContainerSC = styled.div`
    display: flex;
    width: 135px;

    @media (max-width: 600px) {
        flex-direction: column-reverse;
        /* flex-basis: 45px; */
        width: 35px;
    }
`;

const BasketItemDeacreaseSC = styled.button`
    cursor: pointer;

    background: none;
    border: none;

    border-radius: 0.3125rem 0rem 0rem 0.3125rem;
    border: 1.25px solid #646776;
    border-right: none;

    width: 1.875rem;
    /* flex: 1; */

    height: 2.44rem;

    @media (max-width: 600px) {
        border: 1.25px solid #646776;
        border-radius: 0 0 0.3125rem 0.3125rem;
        border-top: none;
        width: 100%;
    }
`;

const BasketItemQuantity = styled.input`
    color: #646776;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1.25px solid #646776;

    padding: 0 0.39rem;

    height: 2.44rem;
    width: 0;
    flex: 1;

    &[type="number"] {
        -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &:focus {
        outline: none;
    }

    @media (max-width: 600px) {
        font-size: 0.9rem;
        flex-basis: 2rem;
        width: auto;
    }
`;

const BasketItemIncreaseSC = styled.button`
    cursor: pointer;

    background: none;
    border: none;

    border-radius: 0rem 0.3125rem 0.3125rem 0rem;
    border: 1.25px solid #646776;
    border-left: none;

    width: 1.875rem;
    /* flex: 1; */
    height: 2.44rem;

    @media (max-width: 600px) {
        border: 1.25px solid #646776;
        border-radius: 0.3125rem 0.3125rem 0 0;
        border-bottom: none;
        width: 100%;
    }
`;

const BasketItemDeleteItemSC = styled.img`
    cursor: pointer;

    width: 1.05469rem;
    height: 1.18719rem;

    margin-left: 1.22rem;

    @media (max-width: 600px) {
        display: none;
    }
`;

export const useBasketItemStyles = () => ({
    BasketItemWrapper,
    BasketItemImageSC,
    BasketItemPriceSC,
    BasketItemTitleSC,
    BasketItemVendorSC,
    BasketItemContainerSC,
    BasketItemDeacreaseSC,
    BasketItemIncreaseSC,
    BasketItemQuantity,
    BasketItemControllSC,
    BasketItemDeleteItemSC,
    BasketItemControllContainerSC,
});